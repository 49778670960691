<script>
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  let headers = ['Email', '', '', 'Business Buddy', ''];
  export let keys = ['email', '', '', 'business_buddy', 'delete'];
  export let users = [];
</script>

<table class="min-w-full divide-y divide-gray-200">
  <thead class="px-4 border-none">
    <tr class="bg-white dark:bg-gray-800">
      {#each headers as header, i}
        <th
          scope="col"
          class:px-6={i > 0}
          class="py-3 text-left small-text text-gray-600 dark:text-gray-300 dark:font-medium font-light"
        >
          {header}
        </th>
      {/each}
    </tr>
  </thead>
  <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200 border-none">
    {#each users as d, j}
      <tr
        class="border-none cursor-pointer hover:bg-gray-300 dark:text-gray-300 dark:hover:bg-gray-700"
      >
        {#each keys as key}
          <td class="px-6 py-2 whitespace-nowrap">
            <!-- I'm sure there's a better way to do this but it works and I need to ship it -->
            <div
              class="flex items-center"
              class:justify-center={key.toLowerCase() == 'delete'}
            >
              <div>
                {#if typeof d[key] === 'boolean' && d[key] === true}
                  <div
                    class="hidden md:flex text-xs font-medium text-socius-primary dark:text-socius-secondary"
                    on:click={() => dispatch('notify', d)}
                  >
                    <i class="fas fa-check" />
                  </div>
                {:else if key.toLowerCase() == 'delete'}
                  <div
                    class="text-xs font-medium text-red-500 flex items-center justify-center"
                    on:click={() => dispatch('delete', d)}
                  >
                    <i class="fas fa-trash" />
                  </div>
                {:else if key.toLowerCase() == ''}
                  <div
                    class="text-xs font-medium text-red-500 items-center justify-center"
                  />
                {:else if key.toLowerCase() == 'email'}
                  <div
                    class="text-xs font-medium text-gray-900 dark:text-gray-300"
                  >
                    {d[key]}
                  </div>
                {/if}
              </div>
            </div>
          </td>
        {/each}
      </tr>
    {/each}
  </tbody>
</table>

<style>
  .small-text {
    font-size: 0.5rem;
  }
/*# sourceMappingURL=src/components/UserTable.svelte.map */</style>
