<script>;
export let color = "#FF3E00";
export let unit = "px";
export let duration = "1s";
export let size = "60";
</script>

<style>
  .wrapper {
    width: var(--size);
    height: var(--size);
  }
  .circle {
    width: var(--size);
    height: var(--size);
    background-color: var(--color);
    -webkit-animation-duration: var(--duration);
            animation-duration: var(--duration);
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: scaleOut var(--duration) ease-in-out infinite;
            animation: scaleOut var(--duration) ease-in-out infinite;
  }
  @-webkit-keyframes scaleOut {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
  @keyframes scaleOut {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
/*# sourceMappingURL=node_modules/svelte-loading-spinners/dist/ScaleOut.svelte.map */</style>

<div
  class="wrapper"
  style="--size: {size}{unit}; --color: {color}; --duration: {duration}; --duration: {duration};">
  <div class="circle" />
</div>
