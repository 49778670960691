<script>export let size = "60";
export let unit = "px";
export let ballTopLeft = "#FF3E00";
export let ballTopRight = "#F8B334";
export let ballBottomLeft = "#40B3FF";
export let ballBottomRight = "#676778";
export let duration = "1.5s";
</script>

<style>
  .wrapper {
    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    box-sizing: border-box;
  }
  .inner {
    transform: scale(calc(var(--floatSize) / 52));
  }
  .ball-container {
    -webkit-animation: ballTwo var(--duration) infinite;
            animation: ballTwo var(--duration) infinite;
    width: 44px;
    height: 44px;
    flex-shrink: 0;
    position: relative;
  }
  .single-ball {
    width: 44px;
    height: 44px;
    position: absolute;
  }
  .ball {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    -webkit-animation: ballOne var(--duration) infinite ease;
            animation: ballOne var(--duration) infinite ease;
  }
  .ball-top-left {
    background-color: var(--ballTopLeftColor);
    top: 0;
    left: 0;
  }
  .ball-top-right {
    background-color: var(--ballTopRightColor);
    top: 0;
    left: 24px;
  }
  .ball-bottom-left {
    background-color: var(--ballBottomLeftColor);
    top: 24px;
    left: 0;
  }
  .ball-bottom-right {
    background-color: var(--ballBottomRightColor);
    top: 24px;
    left: 24px;
  }
  @-webkit-keyframes ballOne {
    0% {
      position: absolute;
    }
    50% {
      top: 12px;
      left: 12px;
      position: absolute;
      opacity: 0.5;
    }
    100% {
      position: absolute;
    }
  }
  @keyframes ballOne {
    0% {
      position: absolute;
    }
    50% {
      top: 12px;
      left: 12px;
      position: absolute;
      opacity: 0.5;
    }
    100% {
      position: absolute;
    }
  }
  @-webkit-keyframes ballTwo {
    0% {
      transform: rotate(0deg) scale(1);
    }
    50% {
      transform: rotate(360deg) scale(1.3);
    }
    100% {
      transform: rotate(720deg) scale(1);
    }
  }
  @keyframes ballTwo {
    0% {
      transform: rotate(0deg) scale(1);
    }
    50% {
      transform: rotate(360deg) scale(1.3);
    }
    100% {
      transform: rotate(720deg) scale(1);
    }
  }
/*# sourceMappingURL=node_modules/svelte-loading-spinners/dist/Circle3.svelte.map */</style>

<div
  class="wrapper"
  style="--size: {size}{unit}; --floatSize: {size}; --ballTopLeftColor: {ballTopLeft}; --ballTopRightColor: {ballTopRight}; --ballBottomLeftColor: {ballBottomLeft}; --ballBottomRightColor: {ballBottomRight}; --duration: {duration};">
  <div class="inner">
    <div class="ball-container">
      <div class="single-ball">
        <div class="ball ball-top-left">&nbsp;</div>
      </div>
      <div class="contener_mixte">
        <div class="ball ball-top-right">&nbsp;</div>
      </div>
      <div class="contener_mixte">
        <div class="ball ball-bottom-left">&nbsp;</div>
      </div>
      <div class="contener_mixte">
        <div class="ball ball-bottom-right">&nbsp;</div>
      </div>
    </div>
  </div>
</div>
