<script lang="ts">import { loading, user, idToken, claims } from '../../utils/store';
import ReAuthModal from '../../components/modals/NewUserModal.svelte';
import Tooltip from '../../components/Tooltip.svelte';
import { getNotificationsContext } from 'svelte-notifications';
import { Shadow } from 'svelte-loading-spinners';
import { getAuth, sendEmailVerification } from 'firebase/auth';
import { getApp } from '@firebase/app';
import DarkModeToggle from '../../components/DarkModeToggle.svelte';
import Heading from '../../components/Heading.svelte';
import Input from '../../components/Input.svelte';
const app = getApp();
const firebaseAuth = getAuth(app);
const { addNotification } = getNotificationsContext();
$: disabled = true;
let err = '';
let modal = false;
let reauthenticate = false;
let showTooltip = false;
let showTooltipSave = false;
let loadingBilling = false;
let currentPassword = '';
let email = $user ? $user.email : '';
let displayName = $user ? $user.displayName : '';
function setValues() {
    email = $user ? $user.email : '';
    displayName = $user ? $user.displayName : '';
}
function cancel() {
    setValues();
    disabled = true;
}
function verifyEmail() {
    sendEmailVerification(firebaseAuth.currentUser)
        .then(() => {
        addNotification({
            text: 'Verification email sent. Please check email.',
            position: 'bottom-center',
            type: 'success',
            removeAfter: 2000,
        });
    })
        .catch((err) => {
        console.log('failed to send user verification email', err);
    });
}
function reauthenticateUser() {
    let creds = firebase.auth.EmailAuthProvider.credential($user.email, currentPassword);
    $user
        .reauthenticateWithCredential(creds)
        .then(() => {
        // authenticated = true;
        reauthenticate = false;
        saveUserInfo();
    })
        .catch((error) => {
        if (error.code == 'auth/wrong-password') {
            err = 'Wrong password. Try again or reset password.';
        }
        else if (error.code == 'auth/too-many-requests') {
            err = error.message;
        }
        else {
            console.log(error);
            err =
                'Error reauthenticating. Please try again later or contact support.';
        }
    });
}
function saveUserInfo() {
    if (displayName != $user.displayName) {
        $user
            .updateProfile({
            displayName: displayName,
        })
            .then(() => {
            disabled = true;
        })
            .catch((error) => {
            console.log('Failed to update user profile: ', error);
        });
    }
    if (email != $user.email) {
        $user
            .updateEmail(email)
            .then(() => {
            disabled = true;
        })
            .catch((error) => {
            if (error.code == 'auth/requires-recent-login') {
                // TODO(you): prompt the user to re-provide their sign-in credentials
                reauthenticate = true;
            }
            else {
                console.log('Failed to update email', error);
            }
        });
    }
}
function refreshPermissions() {
    $user
        .getIdTokenResult(true)
        .then((token) => {
        $idToken = JSON.stringify(token.token.replaceAll(/"/g, ''));
        $claims = token.claims;
        addNotification({
            text: 'Successfully refreshed permissions!',
            position: 'bottom-center',
            type: 'success',
            removeAfter: 2000,
        });
    })
        .catch((error) => {
        console.log(error);
    });
}
</script>

{#if modal}
  <ReAuthModal bind:show={modal} />
{/if}

{#if loadingBilling}
  <div class="w-full h-full flex justify-center items-center">
    <Shadow size="60" unit="px" duration="1s" color="#6ea6f3" />
  </div>
{:else if !$loading}
  <Heading
    heading="Account"
    subheading="Manage your personal account details."
  />

  <div
    class="shadow-lg rounded bg-white dark:bg-gray-800 dark:text-white p-6 mb-8"
  >
    <div class="text-sm mb-8">
      <div class="flex justify-between items-center">
        <p class="text-2xl font-bold mb-4">General</p>
        {#if disabled}
          <div class="mr-8">
            <Tooltip text="Edit account information" {showTooltip}>
              <i
                class="flex justify-center items-center cursor-pointer text-gray-600 dark:text-gray-300 fas fa-edit"
                on:click={() => (disabled = false)}
                on:mouseenter={() => (showTooltip = true)}
                on:mouseleave={() => (showTooltip = false)}
              />
            </Tooltip>
          </div>
        {:else}
          <div class="flex">
            <Tooltip text="Save" showTooltip={showTooltipSave}>
              <i
                class="flex justify-center items-center cursor-pointer text-socius-alt fas fa-save"
                on:click={saveUserInfo}
                on:mouseenter={() => (showTooltipSave = true)}
                on:mouseleave={() => (showTooltipSave = false)}
              />
            </Tooltip>
            <span class="ml-4 mr-8">
              <Tooltip text="Cancel" {showTooltip}>
                <i
                  class="flex justify-center items-center cursor-pointer text-red-500 fas fa-ban"
                  on:click={cancel}
                  on:mouseenter={() => (showTooltip = true)}
                  on:mouseleave={() => (showTooltip = false)}
                />
              </Tooltip>
            </span>
          </div>
        {/if}
      </div>
      <div class="flex justify-between items-center">
        <p>Manage information for account <b>{$user.email}</b></p>
        <DarkModeToggle />
      </div>
    </div>

    {#if reauthenticate}
      <div
        class="flex border-2 rounded-sm border-red-500 py-2 px-4 w-full mb-4"
      >
        <div class="w-5/6">
          <label for="displayName" class="text-small text-gray-700">
            Re-enter current password
          </label>
          <input
            bind:value={currentPassword}
            on:keyup={() => (err = '')}
            type="password"
            name="currentPassword"
            class="mb-2 outline-none border-transparent border-b border-gray-600 w-full py-2 pr-4 text-xs bg-transparent"
          />
          {#if err != ''}
            <span class="text-red-500 text-xs"
              ><i class="fas fa-exclamation-circle" />
              {err}</span
            >
            <br />
          {/if}
        </div>
        <div
          class="w-1/6 flex items-center justify-center hover:text-socius-secondary"
        >
          <button
            class="rounded uppercase px-4 py-2 flex items-center justify-center text-xs text-white font-bold bg-socius-alt shadow-lg hover:shadow-xl hover:bg-socius-primary"
            on:click={reauthenticateUser}
            target="_blank"
          >
            Submit
          </button>
        </div>
      </div>
    {/if}

    <div class="flex gap-2 md:gap-16 mb-12">
      <div class="w-full md:w-1/2 pr-0 md:pr-16">
        <Input
          label="Display Name"
          bind:value={displayName}
          {disabled}
          placeholder="George P. Burdell"
        />

        <Input label="User ID" bind:value={$user.uid} disabled={true} />
      </div>

      <div class="w-full md:w-1/2 pr-0 md:pr-20">
        <div class="w-full flex gap-4">
          <div class="w-full md:w-5/6">
            <Input
              label="Email"
              bind:value={email}
              {disabled}
              placeholder="coyote@acme.com"
            />
          </div>

          <div class="w-0 md:w-1/6 hidden md:inline">
            <label
              for="emailVerified"
              class="flex items-center justify-center text-small text-gray-700 dark:text-gray-300"
            >
              Verified
            </label>
            <div
              class:text-gray-300={disabled}
              class:dark:text-gray-500={disabled}
              class="flex items-center justify-center relative top-4"
            >
              {#if $user.emailVerified}
                <div
                  class="text-xs font-medium"
                  class:text-socius-secondary={!disabled}
                  class:dark:text-socius-secondary={!disabled}
                >
                  <i class="fas fa-check" />
                </div>
              {:else}
                <div
                  class="text-xs font-medium"
                  class:text-red-500={!disabled}
                  class:dark:text-red-500={!disabled}
                >
                  <i class="fas fa-times" />
                </div>
              {/if}
            </div>
          </div>
        </div>
      </div>
    </div>

    {#if !$user.emailVerified}
      <button
        class="w-48 rounded uppercase px-8 py-4 flex items-center justify-center text-xs text-white font-bold bg-socius-alt shadow-lg hover:shadow-xl hover:bg-socius-primary"
        on:click={verifyEmail}
        target="_blank"
      >
        Verify Email Address
      </button>
    {/if}
  </div>

  <div
    class="shadow-lg rounded bg-white dark:bg-gray-800 dark:text-white p-6 mb-8"
  >
    <div class="text-sm mb-8">
      <div class="flex justify-between items-center">
        <p class="text-2xl font-bold mb-4">Security</p>
      </div>
      <p>Manage security options for account <b>{$user.email}</b></p>
    </div>

    <div class="flex gap-2 md:gap-16 mb-12">
      <div class="w-full md:w-1/2 pr-0 md:pr-16">
        <button
          class="w-48 rounded uppercase px-8 py-4 flex items-center justify-center text-xs text-white font-bold bg-socius-alt shadow-lg hover:shadow-xl hover:bg-socius-primary"
          on:click={refreshPermissions}
          target="_blank"
        >
          Refresh Permissions
        </button>
      </div>
    </div>
  </div>
{/if}

<style>
  .text-small {
    font-size: 0.65rem;
  }
/*# sourceMappingURL=src/pages/account/index.svelte.map */</style>
