<script>
  import { routes } from '../../utils/store.ts';
  import LogoutModal from '../LogoutModal.svelte';
  import { fade } from 'svelte/transition';
  import { quartOut } from 'svelte/easing';

  export let show = false;

  let logout = false;

  function appear(node, { duration }) {
    return {
      duration,
      css: (t) => {
        const eased = quartOut(t);
        return `transform: scale(${0.8 * 1.25 ** eased});
                  opacity: ${eased};`;
      },
    };
  }
</script>

{#if logout}
  <LogoutModal bind:logout />
{/if}

<div
  class="w-screen inline md:hidden"
  class:translate-x-0={show}
  class:-translate-x-full={!show}
>
  <div
    class="px-4 py-2 bg-white-400 dark:bg-gray-800 shadow-lg flex justify-between items-center text-socius-primary dark:text-white"
  >
    <span class="cursor-pointer noprint" on:click={() => (show = !show)}>
      <i class="fa fa-bars" aria-hidden="true" />
    </span>

    <div>
      <img
        src="/socius-logo.svg"
        class="inline h-8 mr-2 noprint"
        alt="Socius Logo"
      />
      <span class="noprint">Portal</span>
    </div>

    <span class="invisible noprint"><i class="fas fa-user-circle" /></span>
  </div>

  {#if show}
    <div
      class="flex w-screen bg-white absolute z-50"
      in:appear={{ duration: 150 }}
      out:fade={{ duration: 150 }}
    >
      <div
        class="flex-grow items-center rounded shadow-lg"
        class:block={show}
        class:hidden={!show}
      >
        <ul class="flex flex-col list-none dark:bg-gray-800">
          {#each routes as route}
            <li class="flex items-center" on:click={() => (show = false)}>
              <a
                class=" text-gray-700 dark:text-gray-300 px-2 py-2 flex items-center text-xs uppercase font-bold"
                href={route.route}
              >
                <i
                  class="{route.icon} mx-1 text-socius-primary w-6 flex items-center justify-center"
                />{route.heading}
              </a>
            </li>
          {/each}
          <!-- <li class="flex items-center" on:click={() => (show = false)}>
            <a
              class=" text-gray-700 px-2 py-2 flex items-center text-xs uppercase font-bold"
              href="settings"
              ><i
                class="fa fa-cog mx-1 text-socius-primary w-6 flex items-center justify-center"
              /> Settings
            </a>
          </li> -->
          <li class="flex items-center" on:click={() => (show = false)}>
            <span
              on:click={() => (logout = true)}
              class=" text-gray-700 dark:text-gray-300 px-2 py-2 flex items-center text-xs uppercase font-bold"
              href="logout"
              ><i
                class="fa fa-sign-out-alt mx-1 text-socius-primary w-6 flex items-center justify-center"
              />Logout
            </span>
          </li>
        </ul>
      </div>
    </div>
  {/if}
</div>

<style type="text/css">
  @media print {
    .noprint {
      display: none;
    }
  }
/*# sourceMappingURL=src/components/nav/MobileSidenav.svelte.map */</style>
