<script>;
export let color = "#FF3E00";
export let unit = "px";
export let duration = "0.75s";
export let size = "60";
</script>

<style>
  .circle {
    height: var(--size);
    width: var(--size);
    border-color: var(--color) transparent var(--color) var(--color);
    border-width: calc(var(--size) / 15);
    border-style: solid;
    -o-border-image: initial;
       border-image: initial;
    border-radius: 50%;
    -webkit-animation: var(--duration) linear 0s infinite normal none running rotate;
            animation: var(--duration) linear 0s infinite normal none running rotate;
  }
  @-webkit-keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
/*# sourceMappingURL=node_modules/svelte-loading-spinners/dist/Circle.svelte.map */</style>

<div
  class="circle"
  style="--size: {size}{unit}; --color: {color}; --duration: {duration}" />
