<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { redirect } from '@sveltech/routify';
import { onMount } from 'svelte';
import { createNew, currentApp, selectedOrg, selectedOrgId, loading, user, orgUsers, idToken, } from '../../utils/store';
import AppUserTable from '../../components/AppUserTable.svelte';
import NewUserAppModal from '../../components/modals/NewUserAppModal.svelte';
import Select from '../../components/Select.svelte';
import { getNotificationsContext } from 'svelte-notifications';
import { Shadow } from 'svelte-loading-spinners';
import { query, collection, where, getDocs, onSnapshot, addDoc, } from 'firebase/firestore';
import { db, functions } from '../../utils/services';
import Heading from '../../components/Heading.svelte';
const { addNotification } = getNotificationsContext();
const stripe = Stripe(`${process.env.STRIPE_KEY}`);
$createNew = false;
$: value = '';
let modal = false;
$: users = $orgUsers;
$: selectedUsers = [];
$: subscriptionCount = 0;
let yearly = false; // wether the user selected monthly or yearly
let price = '';
let monthlyPrice; // the price object from firestore
let yearlyPrice; // the price object from firestore
let monthlyPriceAmount; // the actual value from the price object
let yearlyPriceAmount; // the actual value from the price object
$: yearly ? (price = yearlyPriceAmount) : (price = monthlyPriceAmount);
let loadingBilling = false;
const getOptionLabel = (option) => option.email;
const getSelectionLabel = (option) => option.email;
const optionIdentifier = 'id';
function showUserModal() {
    modal = true;
}
function createUserRecord(user) {
    return __awaiter(this, void 0, void 0, function* () {
        // console.log('app: ', $currentApp);
        // console.log('price: ', price);
        const res = yield fetch(`/create-user`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${$idToken.replaceAll(/"/g, '')}`,
            },
            credentials: 'include',
            body: JSON.stringify({
                selectedOrg: $selectedOrg,
                user: user,
            }),
        });
        const data = yield res.json();
        if (res.ok && data.success) {
            return true;
        }
        else {
            return false;
        }
    });
}
function addNewUser(event) {
    let newUser = event.detail;
    console.log('adding new user');
    selectedUsers = [...selectedUsers, newUser];
    let oldUsers = [...users];
    $orgUsers = [...users, ...selectedUsers];
    createUserRecord(newUser)
        .then((res) => {
        if (res == true) {
            console.log('sucessfully created user record ');
        }
        else {
            console.log('failed to create auth user');
        }
    })
        .catch((err) => {
        console.log('failed to create user auth record', err);
    });
    addDoc(collection(db, `org_users/${$selectedOrg.id}/users`), newUser)
        .then((res) => {
        addNotification({
            text: 'Successfully added user!',
            position: 'bottom-center',
            type: 'success',
            removeAfter: 2000,
        });
        users = oldUsers;
    })
        .catch((err) => {
        console.log('Error adding user: ', err);
        addNotification({
            text: 'Failed to add user.',
            position: 'bottom-center',
            type: 'error',
            removeAfter: 2000,
        });
    });
}
function startSubscription(price, users, quantity) {
    return __awaiter(this, void 0, void 0, function* () {
        console.log('new trial!');
        const date = new Date();
        date.setDate(date.getDate() + 90);
        const end_trial = date.getTime();
        // Add a new document with a generated id.
        const docRef = yield addDoc(collection(db, `socius_customers/${$user.uid}/checkout_sessions`), {
            price: price.id,
            users: users,
            trial_end: end_trial,
            client_reference_id: $selectedOrgId,
            customerEmail: $selectedOrg.repEmail,
            success_url: `${window.location.origin}/products`,
            cancel_url: `${window.location.origin}/products/users`,
            allow_promotion_codes: true,
            line_items: [
                {
                    price: price.id,
                    // For metered billing, do not pass quantity
                    quantity: quantity,
                },
            ],
        });
        console.log('Document written with ID: ', docRef.id);
        // Wait for the CheckoutSession to get attached by the extension
        onSnapshot(docRef, (snap) => {
            const { error, sessionId } = snap.data();
            if (error) {
                // Show an error to your customer and
                // inspect your Cloud Function logs in the Firebase console.
                loadingBilling = false;
                console.log(`An error occured: ${error.message}`);
                addNotification({
                    text: 'Failed to update users.',
                    position: 'bottom-center',
                    type: 'error',
                    removeAfter: 2000,
                });
            }
            if (sessionId) {
                // We have a session, let's redirect to Checkout
                console.log('Redirecting to checkout');
                stripe.redirectToCheckout({ sessionId });
                return true;
            }
        });
    });
}
function handleReassignment(users, price) {
    return __awaiter(this, void 0, void 0, function* () {
        // console.log('app: ', $currentApp);
        // console.log('price: ', price);
        const res = yield fetch(`/update-users-for-app`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${$idToken.replaceAll(/"/g, '')}`,
            },
            credentials: 'include',
            body: JSON.stringify({
                selectedOrg: $selectedOrg,
                users: users,
                app: $currentApp,
                price: price,
            }),
        });
        // console.log('Response from hanlding reassignment: ', data);
        loadingBilling = false;
        // return data.success;
        const data = yield res.json();
        if (res.ok && data.success) {
            addNotification({
                text: 'Successfully updated!',
                position: 'bottom-center',
                type: 'success',
                removeAfter: 2000,
            });
        }
        else {
            if (data.failedUsers) {
                console.log(data.failedUsers);
            }
            addNotification({
                text: 'Failed to update.',
                position: 'bottom-center',
                type: 'error',
                removeAfter: 2000,
            });
        }
    });
}
function updateUsers() {
    loadingBilling = true;
    // console.log('selected Users: ', selectedUsers);
    console.log(`selected Users: ${selectedUsers.length}, Sub Count: ${subscriptionCount}`);
    if (selectedUsers.length <= subscriptionCount) {
        // handle reassignments
        console.log('handling reassignments...');
        yearly
            ? handleReassignment(selectedUsers, yearlyPrice)
            : handleReassignment(selectedUsers, monthlyPrice);
    }
    else {
        // handle getting new subscribers
        let quantity = selectedUsers.length - subscriptionCount;
        console.log('checking out!');
        try {
            yearly
                ? startSubscription(yearlyPrice, selectedUsers, quantity)
                : startSubscription(monthlyPrice, selectedUsers, quantity);
        }
        catch (error) {
            console.log('booo', error.message);
        }
    }
}
function handleSelect(event) {
    // console.log('selected item', event.detail);
    let user = event.detail;
    user[$currentApp.role.toLowerCase()] = true;
    user['delete'] = '';
    selectedUsers = [...selectedUsers, user];
    users = users.filter((u) => u.email != event.detail.email);
    value = null;
}
function removeUser(event) {
    // console.log('Removing user: ', event.detail);
    selectedUsers = selectedUsers.filter((u) => u.email != event.detail.email);
    users = [...users, event.detail];
    value = undefined;
}
function convertToMoney(amount) {
    return amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
function getSubscriptionCount() {
    // console.log('current app:', $currentApp.name.toLowerCase());
    let q = query(collection(db, `socius_customers/${$user.uid}/subscriptions`), where('Status', 'in', ['trialing', 'active']), where('Role', '==', `${$currentApp.name.toLowerCase()}`));
    getDocs(q)
        .then((querySnapshot) => {
        querySnapshot.forEach((document) => {
            subscriptionCount = subscriptionCount + document.data().Quantity;
        });
    })
        .catch((err) => {
        console.log('Error getting documents for sub count: ', err);
    });
}
function calcUsers() {
    let oldUsers = users;
    for (let i = 0; i < users.length; i++) {
        if (users[i][$currentApp.role.toLowerCase()] != undefined &&
            users[i][$currentApp.role.toLowerCase()] == true) {
            selectedUsers = [...selectedUsers, users[i]];
            if (users[i].paymentType == 'yearly') {
                yearly = true;
            }
            oldUsers = oldUsers.filter((u) => u.email != users[i].email);
        }
    }
    users = oldUsers;
    for (let i = 0; i < $currentApp.prices.length; i++) {
        // Stripe stores amount without the decimal (ex. $4.99 is stored as 499) so divide
        // by 100 to get the actual value
        if ($currentApp.prices[i].interval == 'month') {
            monthlyPrice = $currentApp.prices[i];
            monthlyPriceAmount = convertToMoney(monthlyPrice.unit_amount / 100);
        }
        else if ($currentApp.prices[i].interval == 'year') {
            yearlyPrice = $currentApp.prices[i];
            yearlyPriceAmount = convertToMoney(yearlyPrice.unit_amount / 100);
        }
    }
}
onMount(() => {
    if ($selectedOrg === undefined && !$loading) {
        $createNew = true;
        $redirect('/organizations');
    }
    if ($currentApp == undefined) {
        $redirect('/products');
    }
    else {
        getSubscriptionCount();
        calcUsers();
    }
});
</script>

{#if modal}
  <NewUserAppModal bind:show={modal} on:newuser={addNewUser} {yearly} />
{/if}

{#if loadingBilling}
  <div class="w-full h-full flex justify-center items-center">
    <Shadow size="60" unit="px" duration="1s" color="#6ea6f3" />
  </div>
{:else if $currentApp != undefined}
  <div class="text-sm dark:text-gray-300">
    <p
      class="text-small font-bold mb-6 cursor-pointer"
      on:click={$redirect('/products')}
    >
      <i class="fas fa-chevron-left mr-2" />Products
    </p>
    <Heading
      heading="Socius {$currentApp.name}"
      subheading={$currentApp.description}
    />
  </div>

  <div class="mt-8">
    <div class="my-1 px-1 w-full lg:my-4 lg:px-4 h-full">
      <div
        class="bg-white dark:bg-gray-800 dark:text-white rounded w-full shadow hover:shadow-lg duration-4 p-4"
      >
        <div class="md:flex md:gap-16 mb-12">
          <div class="w-full md:w-1/2 mb-6 md:mb-0">
            <div class="mb-4">
              <p class="font-bold text-2xl">Users</p>
              <p class="text-xs mt-4">
                We're excited to simplify how you do business.
              </p>
              <p class="text-xs">Add users below to give access.</p>
              <p class="text-small mt-2 text-red-500">
                Removing users here will not affect billing.
              </p>
              <p class="text-small mt-2 text-socius-primary dark:text-gray-600">
                Remaining Seats: {subscriptionCount - selectedUsers.length}
              </p>
            </div>

            <span class="text-small text-gray-600">User Email</span>
            <div class="flex">
              <div class=" w-48">
                <Select
                  items={users}
                  showIndicator={true}
                  {getOptionLabel}
                  {getSelectionLabel}
                  {optionIdentifier}
                  bind:selectedValue={value}
                  on:notify={handleSelect}
                  placeholder="Add User Email"
                  noOptionsMessage="Add users to business first"
                />
              </div>
              <span
                class="text-small text-socius-secondary ml-4 pt-1 cursor-pointer"
                on:click={showUserModal}>+Add New User</span
              >
            </div>

            <!-- Monthly/yearly toggle -->
            <div class="flex mt-8 items-center gap-4">
              <span class="text-xs text-gray-600 dark:text-gray-300"
                >Monthly</span
              >

              <label
                for="userType"
                class="flex items-center justify-between cursor-pointer"
              >
                <!-- toggle switch -->
                <div class="relative ml-1">
                  <!-- invisible input -->
                  <input
                    bind:checked={yearly}
                    id="userType"
                    type="checkbox"
                    class="hidden"
                  />
                  <!-- line -->
                  <div class="w-8 h-3 bg-gray-400 rounded-full shadow-inner" />
                  <!-- dot -->
                  <div
                    class="toggle__dot absolute inset-y-0 left-0 w-5 h-5 bg-socius-secondary rounded-full shadow"
                  />
                </div>
              </label>

              <span class="text-xs text-gray-600 dark:text-gray-300"
                >Yearly</span
              >
            </div>
          </div>

          <div class="w-full md:w-1/2">
            <AppUserTable
              users={selectedUsers}
              {price}
              on:delete={removeUser}
            />
          </div>
        </div>

        <div class="w-full flex justify-between">
          <!-- <p class="text-small flex items-center italic">
            Make sure to logout to update access.
          </p> -->
          <button
            class="w-40 rounded uppercase px-8 py-4 flex items-center justify-center text-xs text-white font-bold bg-socius-secondary shadow-lg hover:shadow-xl"
            on:click={updateUsers}
            target="_blank"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
{/if}

<style type="postcss">
  .text-small {
    font-size: 0.65rem;
  }

  .toggle__dot {
    top: -0.25rem;
    left: -0.25rem;
    transition: all 0.25s ease-in-out;
  }

  input:checked ~ .toggle__dot {
    transform: translateX(100%);
    --tw-translate-x: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-bg-opacity: 1;
    background-color: rgb(56 211 255 / var(--tw-bg-opacity));
  }
/*# sourceMappingURL=src/pages/products/users.svelte.map */</style>
