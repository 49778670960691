<script>;
export let color = "#FF3E00";
export let unit = "px";
export let duration = "3s";
export let size = "60";
</script>

<style>
  .square {
    height: var(--size);
    width: var(--size);
    background-color: var(--color);
    -webkit-animation: squareDelay var(--duration) 0s infinite
      cubic-bezier(0.09, 0.57, 0.49, 0.9);
            animation: squareDelay var(--duration) 0s infinite
      cubic-bezier(0.09, 0.57, 0.49, 0.9);
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    perspective: 100px;
    display: inline-block;
  }
  @-webkit-keyframes squareDelay {
    25% {
      transform: rotateX(180deg) rotateY(0);
    }
    50% {
      transform: rotateX(180deg) rotateY(180deg);
    }
    75% {
      transform: rotateX(0) rotateY(180deg);
    }
    100% {
      transform: rotateX(0) rotateY(0);
    }
  }
  @keyframes squareDelay {
    25% {
      transform: rotateX(180deg) rotateY(0);
    }
    50% {
      transform: rotateX(180deg) rotateY(180deg);
    }
    75% {
      transform: rotateX(0) rotateY(180deg);
    }
    100% {
      transform: rotateX(0) rotateY(0);
    }
  }
/*# sourceMappingURL=node_modules/svelte-loading-spinners/dist/Square.svelte.map */</style>

<div
  class="square"
  style="--size: {size}{unit}; --color: {color}; --duration: {duration};" />
