<script lang="ts">import FirebaseAuthUi from './FirebaseAuthUI.svelte';
import { firebaseConfig } from '../../utils/services';
import { redirect } from '@sveltech/routify';
</script>

<div
  class="flex items-center justify-center bg-gray-50 dark:bg-gray-700 py-12 px-4 sm:px-6 lg:px-8"
>
  <div class="shadow-lg p-4 max-w-md w-full dark:bg-gray-800">
    <div class="mb-4">
      <img class="mx-auto h-16 w-auto" src="/socius-logo.svg" alt="Workflow" />
      <h2
        class="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-white"
      >
        Welcome to the Socius Portal!
      </h2>
      <p class="mt-2 text-center text-xs text-gray-600 dark:text-gray-400">
        Sign in to continue
      </p>
      <p class="mt-2 text-center extra-small-text text-socius-alt">
        If you don't have an account, it will create one for you as you sign in.
      </p>

      <p
        class="mt-2 text-center extra-small-text text-gray-600 dark:text-gray-400"
      >
        Please remember to use a strong password (upper and lowercase 14+
        characters with special symbols and numbers). Never share or re-use
        passwords.
      </p>
    </div>

    <!-- <div id="firebaseui-auth-container" /> -->
    <FirebaseAuthUi {firebaseConfig} />
  </div>
</div>

<style>
  .extra-small-text {
    font-size: 0.6rem;
  }
/*# sourceMappingURL=src/components/auth/Login.svelte.map */</style>
